/* global styles */
@import 'typography.css';
@import 'links.css';
@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900&subset=latin,cyrillic-ext");

:global(body) {
  min-height: 100%;
  height: auto;
  position: relative;
  font-family: 'Roboto', sans-serif !important;
  padding-bottom: 40px;
}

h1, h2, h3, h4 {
    font-weight: 300;
    margin: 5px;
}

.container-fluid {
  margin-top: 50px;
  padding: 25px;
}
@media (max-width: 991px) {
    .container-fluid{
        padding: 10px;
    }
}
.catalog-paper {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: unset;
    display: inline-block;
    width: 100%;
}

.homepaper-icon {
    height: 100px !important;
    width: 100px !important;
}

.row.small-gutters {
  margin-right: 5px;
  margin-left: 5px;
}
.row.small-gutters > [class^="col-"],
.row.small-gutters > [class*=" col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.row.display-flex {
    display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
}

div[class^="col-"].no-gutter {
    padding-right: 0px;
    padding-left: 0px;
}

.row.no-vert-margin {
    margin-top: 0px;
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .row.small-gutters {
        padding-bottom: 50px;
    }
}

.row.full-width {
    width: 100%;
}

div[role="tooltip"] {
    margin-top: -85px;
}

div[role="tooltip"][x-placement="bottom"]{
    margin-top: -60px;
}

div[role="tooltip"] > div {
    font-size: 16px;
}

div[id^="ht_"]{
    margin-top: -50px;
}

.ht_clone_top_left_corner,
.ht_clone_left,
.ht_clone_top {
    z-index: 7 !important;
}

.split-panes {
    margin-top: -50px;
}

.splitter-layout.splitter-layout-vertical {
    height: 75%;
}

.flatpickr-input {
    height: 46px;
    text-align: center;
    border: none;
}