html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
  //scroll-snap-type: y mandatory;

}
// Colors
$green: #7ac142;
$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  position: relative;
  margin: auto;
  width: 56px;
  height: 56px;
  margin-bottom: 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 1s ease-in-out 1s forwards, scale 1s ease-in-out 1s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 1s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 2s $curve 2s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
.homepage-container{
  min-height: 89.7vh;
  overflow: hidden;
  min-width: 100vw;
  padding-top: 0px;
  position: relative;
}
.homepage-paper {
  background-color: white;
  border-radius: 0px;

  transform: translate(-50%, 20%);
  padding: 64px 48px;
  position: relative;
  left: 50%;
  max-width: 552px;
  margin-bottom: 200px;
}

.homepage-transparent-image {
  display: block;
  position: absolute;
  opacity: 0.1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.octo-uimaterial {

  * {
    box-sizing: border-box;
  }

  section.scrollSection {
    min-height: 100vh;
    position: relative;
    text-align: center;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }

  .speech-bubble p {
    font-size: 18px;
  }

  .speech-bubble {

    position: absolute;
    color: black !important;
    width: 200px;
    bottom: 22vh;
    left: calc(4vw + 20vh);
    padding: 15px 30px;
    margin: 0;
    background: #fff;
    /* Using longhand to avoid inconsistencies between Safari 4 and Chrome 4 */
    -webkit-border-top-left-radius: 25px 50px;
    -webkit-border-top-right-radius: 25px 50px;
    -webkit-border-bottom-right-radius: 25px 50px;
    -webkit-border-bottom-left-radius: 25px 50px;
    -moz-border-radius: 25px / 50px;
    border-radius: 25px / 50px;
  }


  .speech-bubble:after {
    content: "";
    position: absolute;
    bottom: -20%;
    left: 14%;
    border-width: 0 0 40px 20px;
    border-style: solid;
    border-color: transparent #fff;
    /* reduce the damage in FF3.0 */
    display: block;
    width: 0;
  }

  #left-logo-landing {
    height: 25vh;
    position: absolute;
    left: 5vw;
    bottom: -3vh;
    transform: rotate(10deg);
    background-repeat: no-repeat;
  }

  #right-logo-landing {
    width: 16vh;
    position: absolute;
    right: -2vw;
    top: 5vh;
    transform: rotate(-100deg);
    fill: none;
  }

  .thumbnail-part {
    background-color: #574F4F;
    height: 50vh;
    overflow: hidden;
    position: relative;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
  }

  .navbar-toggler {
    margin-top: -10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
  }

  .navbar .navbar-toggler .navbar-toggler-icon {
    background-color: #574F4F;
  }


  .navbar .navbar-nav .nav-item .nav-link:hover {
    transition: all 500ms;
    text-shadow: 2px 2px 8px #574F4F;
    cursor: pointer;
    color: white !important;
  }

  button.nav-link.centered-nav-link {
    border-left: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 0 !important;
  }

  button.nav-link {
    background-color: transparent;
    border: none;
    box-shadow: none;
    //padding: 4px 15px 4px 15px !important;

  }

  button.nav-link:hover {
    background-color: rgba(233, 82, 41, 0.9);
  }

  button.navbar-brand {
    position: fixed;
    background-color: transparent;
    border: none;
  }

  //navigation bar background color
  .navbar.navbar-transparent {
    //box-shadow: 0px 0px 3px #574F4F;
    color: #574F4F;
    //background-color: rgba(255, 255, 255, 0.3) !important;
    //background-image: linear-gradient(to right,rgb(233, 82, 41) 0.5%, rgba(255, 255, 255, 0.96) 5% );
    padding-top: 20px;
  }

  .navbar-translate {
    transition: all 1s ease !important;
  }

  .navbar.navbar-transparent {
    color: #574F4F;
    /*box-shadow: -2px 2px 4px #574F4F;*/
    text-align: center;
    justify-content: center;
    align-content: center;
  }

  .btn-danger {
    border-radius: 10px !important;
  }

  .btn-danger:hover {
    background-color: #E95229 !important;
    box-shadow: 0px 15px 20px rgba(233, 82, 41, 0.6) !important;
    transform: translateY(-7px) !important;

  }

  .collapsed-navbar {
    left: 0px;
  }

  .uncollapsed-navbar {
    left: -230px;
  }

  .collapsed-menu {
    width: 230px;
  }

  .uncollapsed-menu {
    width: 460px;
  }

  .verticalLine {
    width: 4px;
    height: 50px;
    border-left: 4px solid rgba(233, 82, 41, 1);
    box-shadow: 2px 2px 4px #574F4F;
    margin-bottom: -12px;
  }

  .verticalLine:hover {
    cursor: pointer;
  }

  .white-indicator {
    border-left: 4px solid white;
  }

  .footer {
    position: absolute;
    height: 40px;
    bottom: 0;
  }

  .container, .navbar-transparent {
    margin-top: 20px;
  }


  .index-pills {
    padding: 3% 5%;
    overflow: hidden;
    min-height: 50vh;
    position: relative;
    margin: 0;
    align-content: center;
    justify-content: center;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }

  .octo-footer {
    //position: absolute;
    bottom: 0px;
    left: 0;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: -1px 0px 3px #574F4F;

    h5 {
      padding: 5px;
      margin: 5px;
    }

    hr {
      margin: 5px 60% 5px 5%;
      border-top: 3px solid rgba(0, 0, 0, 1);
    }

    .fa {
      font-size: 30px;
      padding: 10px;
      background-color: rgba(233, 82, 41, 1);
      margin: 5px;
      width: 50px;

      position: relative;
      top: 30%;
      text-align: center;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .small-logo {
    display: none;
  }

  .landing-image {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    opacity: 0.1;
    left: 0;
    top: 0;
    max-width: 100vw;
    object-fit: cover;
  }


  .octo-brand-a-link {
    margin-top: -20px;
    font-size: 36px !important;
  }


  #main-index {

    align-content: center;
    justify-content: center;
    text-align: center;
  }

  .bgg {
    position: relative;
    top: 20%;
    opacity: 0.2;
    vertical-align: middle;
  }


  .col-md-6 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .MuiStepIcon-root.MuiStepIcon-active{
    color: #E95229!important;
  }

  .fa-circle {
    color: rgba(233, 82, 41, 1);
    text-shadow: 2px 2px 8px #574F4F !important;
  }


  .title {
    color: #574F4F !important;
  }


  .container {
    max-width: 90% !important;
  }


  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  @media (max-width: 770px) {
    .thumbnail-part {
      height: 50vh;
    }
    #right-logo-landing {
      display: none;
    }
    .speech-bubble p {
      font-size: 10px;
    }
    .speech-bubble {
      width: 130px;
      padding: 10px 25px;
    }
    .btn-danger {
      padding: 9px 18px !important;
    }
    #side-navigator {
      display: none;
    }
    .octo-footer {
      position: initial;

      h5 {
        font-size: 12px;
      }

      .fa {
        font-size: 15px;
        padding: 5px;
        background-color: rgba(233, 82, 41, 1);
        margin: 2px;
        width: 25px;

        position: relative;
        top: 15%;
        text-align: center;
      }

      hr {
        margin: initial;
      }
    }
  }
  @media (max-width: 991px) {
    html {
      scroll-snap-type: none;
    }
    section.scrollSection {
      scroll-snap-stop: unset;
      scroll-snap-align: none;
    }
    button.nav-link {
      margin: 0 !important;
      border-left: none !important;
      padding: 10px 0px 10px 15px !important;
    }
    .navbar-collapse {
      padding-top: 10px;
      border-left: 1px solid #E95229;
      transition: all 1s !important;
    }
    .container, .navbar-transparent {
      margin-top: 20px;
    }

  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


}
